import moment from 'moment'
import { formatDate } from './helper'

/**
 * Format a number into a currency string (USD format).
 * @param {number} number - The number to format as currency.
 * @returns {string} - The formatted currency string.
 */
export function formatCurrency(number) {
  return `$${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}

/**
 * Number formatter for currency (USD).
 * @type {Intl.NumberFormat}
 */
export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
})

/**
 * Check if a string contains whitespace.
 * @param {string} stringVal - The string to check.
 * @returns {boolean} - True if the string contains whitespace, false otherwise.
 */
export const stringValPatternValidation = stringVal => {
  return /\s/g.test(stringVal)
}

/**
 * Get a number from a currency-formatted string.
 * @param {string} currencyAmount - The currency-formatted string.
 * @returns {number|null} - The numeric value or null if not a valid string.
 */
export function getNumberFromCurrency(currencyAmount) {
  // If already a valid number, return immediately.
  if (Number.isInteger(currencyAmount)) {
    return currencyAmount
  }

  if (currencyAmount === null) {
    return null
  }

  if (!(typeof currencyAmount === 'string')) {
    throw new Error('Type of currency amount should be a string or integer.')
  }

  const numberStringFromCurrencyAmount = currencyAmount.replace(/,/g, '').replace(/\$/g, '')
  if (isValidIntegerString(numberStringFromCurrencyAmount)) {
    return parseInt(numberStringFromCurrencyAmount)
  }

  return null
}

/**
 * Check if a string is a valid USA zip code.
 * @param {string} str - The string to check.
 * @returns {boolean} - True if the string is a valid USA zip code, false otherwise.
 */
export const isUSAZipCode = str => {
  return /^\d{5}(-\d{4})?$/.test(str)
}

/**
 * Validate a phone number string.
 * @param {string} str - The phone number string to validate.
 * @returns {boolean} - True if the string is a valid phone number, false otherwise.
 */
export const validatePhoneNumber = str => {
  return /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(str)
}

/**
 * Format a file name from a string.
 * @param {string} string - The input string containing a file name.
 * @returns {string} - The extracted file name.
 */
export function formatFileToName(string) {
  const sections = string.split('-')
  return sections[sections.length - 1]
}

/**
 * Check the number of elements with 'Female Founder' tag in an array of objects.
 * @param {Array} value - The array of objects to check.
 * @returns {number} - The count of elements with 'Female Founder' tag.
 */
export const checkFemaleStyle = value => {
  return value?.filter(key => key?.tags?.includes('Female Founder'))?.length
}

/**
 * Check if a given object is a valid integer string.
 * @param {any} obj - The object to check.
 * @returns {boolean} - True if the object is a valid integer string, false otherwise.
 */
function isValidIntegerString(obj) {
  if (isNaN(obj)) {
    return false
  }

  const floorNumber = Math.floor(Number(obj))
  return floorNumber !== Infinity && String(obj) === obj
}

/**
 * Set the authentication token in the local storage.
 * @param {string|null} token - The authentication token. Set to null to remove the token.
 */
export function setTokenInLocalStorage(token) {
  if (token === null || token === undefined) {
    localStorage.removeItem('token')
    return
  }

  localStorage.setItem('token', token)
  return
}

/**
 * Get the authentication token from local storage.
 * @returns {string|null} - The authentication token or null if not present.
 */
export function getTokenInLocaleStorage() {
  return localStorage.getItem('token')
}

/**
 * Get the identity object from an authentication token.
 * @param {string} token - The authentication token.
 * @throws {Error} - Throws an error if the token is null or undefined.
 * @returns {object} - The identity object extracted from the token.
 */
function getIdentityFromToken(token) {
  if (token === null || token === undefined) {
    throw new Error('Cannot get identity from token if token is null or undefined.')
  }

  const base64Payload = token.split('.')[1]

  const payloadJson = JSON.parse(window.atob(base64Payload))

  return payloadJson.identity
}

/**
 * Get the email address from an authentication token.
 * @param {string} token - The authentication token.
 * @returns {string} - The email address extracted from the token.
 */
export function getEmailFromToken(token) {
  return getIdentityFromToken(token).email
}

/**
 * Get the user type from an authentication token.
 * @param {string} token - The authentication token.
 * @returns {string} - The user type extracted from the token.
 */
export function getUserTypeFromToken(token) {
  return getIdentityFromToken(token).model
}

/**
 * Require a non-empty value and throw an error if it is empty.
 * @param {any} obj - The value to check for emptiness.
 * @param {string} message - The error message to throw if the value is empty.
 * @returns {any} - The non-empty value.
 * @throws {Error} - Throws an error if the value is empty.
 */
export function requireNonEmpty(obj, message) {
  if (obj === null || obj === undefined || obj === '') {
    throw new Error(message)
  }

  return obj
}

/**
 * Get the file name from a URL.
 * @param {string} url - The URL containing the file name.
 * @returns {string} - The extracted file name.
 */
export function getFileNameFromUrl(url) {
  //https://exitfund-client-pdf-bucket.s3-us-west-1.amazonaws.com/62ff18ec485efc30e4041796-copy-1654689972.pdf
  const a = url?.split('/')?.[url?.split('/')?.length - 1]
  let b = a?.split('-')
  b.shift()
  b = b.join('-')
  return b
  // const name = url?.split('-')?.[url?.split('-')?.length - 1]
  // return name
}

// Array of valid incorporation types.
export const INCORPORATION_TYPE = ['C Corporation', 'LLC', 'PBC', 'S Corporation', 'Other']

/**
 * Generate a public URL link based on the environment.
 * @param {string} link - The environment link.
 * @param {string} startupName - The name of the startup.
 * @param {string} invite_code - The invite code for the startup.
 * @returns {string} - The public URL link.
 */
export const publicURLLink = (link, startupName, invite_code) => {
  switch (link) {
    case 'localhost:3000':
      return link + `/startup/${startupName}/${invite_code}`
    case 'stg.exitfund.com':
      return `https://stg.exitfund.com/startup/${startupName}/${invite_code}`
    case 'exitfund.com':
      return `https://www.exitfund.com/startup/${startupName}/${invite_code}`
    default:
      return ''
  }
}

/**
 * Generate a public Monthly Updates URL link based on the environment.
 * @param {string} link - The environment link.
 * @param {string} startupName - The name of the startup.
 * @returns {string} - The public Monthly Updates URL link.
 */
export const publicMonthlyUpdatesURLLink = (link, startupName, personalInviteLink) => {
  switch (link) {
    case 'localhost:3000':
      return `http://${link}/startup/${personalInviteLink}/${startupName}/monthly-updates`
    case 'stg.exitfund.com':
      return `https://stg.exitfund.com/startup/${personalInviteLink}/${startupName}/monthly-updates`
    case 'exitfund.com':
      return `https://www.exitfund.com/startup/${personalInviteLink}/${startupName}/monthly-updates`
    default:
      return ''
  }
}

/**
 * Get a startup name with spaces replaced by underscores.
 * @param {string} name - The original startup name.
 * @returns {string} - The startup name with spaces replaced by underscores.
 */
export const getStartupName = name => {
  return name?.replaceAll(' ', '_')
}

/**
 * Adjust the setup cost array by replacing 'MIN' and 'MAX' values.
 * @param {Array} array - The array to be adjusted.
 * @param {number} min - The minimum value to replace 'MIN'.
 * @param {number} max - The maximum value to replace 'MAX'.
 * @returns {Array} - The adjusted array.
 */

export function getSetupCostArray(array, min, max) {
  if (array?.length) {
    let data = array.map(item => {
      if (item.min === 'MIN') {
        return { ...item, min: min }
      } else if (item.max === 'MAX') {
        return { ...item, max: max }
      } else {
        return item
      }
    })
    return data
  } else {
    return []
  }
}

/**
 * Check if a string contains only letters and spaces.
 * @param {string} value - The string to check.
 * @returns {boolean} - True if the string contains only letters and spaces, false otherwise.
 */
export const nameRegxCheck = value => {
  let newRegx = /^[A-Za-z\s-]*$/
  if (!newRegx.test(value)) {
    return false
  }
  return true
}

/**
 * Format a number as a percentage or display 'N/A'.
 * @param {number} value - The numeric value to format.
 * @returns {string} - The formatted percentage or 'N/A'.
 */
export const NumberValidCheck = value => {
  return value == 0 ? `${value}%` : !value ? 'N/A' : `${value}%`
}

/**
 * Format a number as currency or display 'N/A'.
 * @param {number} value - The numeric value to format.
 * @returns {string} - The formatted currency or 'N/A'.
 */
export const DollerNumberValidCheck = value => {
  return value == 0 ? formatter.format(value) : !value ? 'N/A' : formatter.format(value)
}

/**
 * Display the closing date in the 'MM-DD-YYYY' format.
 * @param {Date} date - The date to be formatted.
 * @returns {string} - The formatted date string.
 */

export const displayClosingdate = date => {
  return moment(date).utc(true).local().format('MM-DD-YYYY')
}

/**
 * Convert a local date to UTC format.
 * @param {Date} date - The local date to be converted.
 * @returns {string} - The date in UTC format.
 */
export const convertLocalToUTC = date => {
  return moment(moment(formatDate(date)).format('YYYY-MM-DDT00:00:00'))
    .utc()
    .format('YYYY-MM-DDTHH:mm:ss')
}

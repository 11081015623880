import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'

import './FooterInvestorMobile.scss'
import syllabus from '../../../assets/images/investers/deals.svg'
import exams from '../../../assets/images/investers/history.svg'
import FilterIcon from '../../../assets/images/filter-icon.svg'
import HistoryInvestedFilterToggle from '../../../pages/Investor/InvestorDeals/components/HistoryInvestedFilterToggle'

const FooterInvestorMobile = props => {
  const [HistoryInvestedFilterSelected, setIsHistoryInvestedFilterSelected] = useState(false)
  const currentPath = props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]
  return (
    <div className="footer-section">
      <Link
        to="/investor/deals"
        className={`footer-list text-dark px-2 ${
          props?.location?.pathname === '/investor/deals' ? 'active' : null
        }`}
      >
        <img src={syllabus} />
        <Link to="/investor/deals">Deals</Link>
      </Link>
      <Link
        to="/investor/history-invested"
        className={`footer-list text-dark px-2 ${
          props?.location?.pathname === '/investor/history-invested' ||
          props?.location?.pathname === '/investor/history-passed'
            ? 'active'
            : null
        }`}
      >
        <img src={exams} />
        <Link to="/investor/history-invested">History</Link>
      </Link>

      {currentPath == 'history-invested' && (
        <div className={`footer-list text-dark`} onClick={() => setIsHistoryInvestedFilterSelected(true)}>
          <img src={FilterIcon} />
          <Link onClick={() => setIsHistoryInvestedFilterSelected(true)}>Filter</Link>
        </div>
      )}

      {/* TODO: Create a component with  name HistoryInvestedFilterToggle */}
      {HistoryInvestedFilterSelected && (
        <HistoryInvestedFilterToggle
          FilterSelected={HistoryInvestedFilterSelected}
          setIsFilterSelected={setIsHistoryInvestedFilterSelected}
        />
      )}
    </div>
  )
}

export default withRouter(FooterInvestorMobile)

import React from 'react'
import './OurPriorInvestments.scss'
/*** Our Prior Investment Include ***/
import PriorInvestor04Updated from '../assets/images/OurPriorInvestors/cairhealth.svg'
import PriorInvestor05Update from '../assets/images/OurPriorInvestors/moneyplanned.svg'
import PriorInvestor07Update from '../assets/images/OurPriorInvestors/Elythea.svg'
import PriorInvestor08Update from '../assets/images/OurPriorInvestors/release.svg'
import Arda from '../assets/images/OurPriorInvestors/Arda.svg'
import Legacy from '../assets/images/OurPriorInvestors/Legacy.svg'
import Wallaroo from '../assets/images/OurPriorInvestors/Wallaroo.svg'
import Earli from '../assets/images/OurPriorInvestors/Earli_logo_RGB 1.svg'

import Arda_Mobile from '../assets/images/OurPriorInvestors/Arda_Mobile.svg'
import Legacy_Mobile from '../assets/images/OurPriorInvestors/Legacy_Mobile.svg'
import Wallaroo_Mobile from '../assets/images/OurPriorInvestors/Wallaroo_Mobile.svg'
import Earli_Mobile from '../assets/images/OurPriorInvestors/Earli_Mobile.svg'
import useMediaQuery from 'use-mediaquery'

const OurPriorInvestments = () => {
  const matches = useMediaQuery('(min-width: 768px)')
  return (
    <div className="justify-content-center align-item-center img-gap main-img-wrap">
      <div className="img-item-list">
        <img src={matches ? Earli : Earli_Mobile} alt="our Prior-Investers" height="50" width="auto" />
      </div>
      <div className="img-item-list">
        <img src={matches ? Wallaroo : Wallaroo_Mobile} alt="our Prior-Investers" height="50" width="auto" />
      </div>
      <div className="img-item-list">
        <img src={matches ? Arda : Arda_Mobile} alt="our Prior-Investers" height="50" width="auto" />
      </div>
      <div className="img-item-list">
        <img src={PriorInvestor04Updated} alt="our Prior-Investers" height="50" width="auto" />
      </div>
      <div className="img-item-list">
        <img src={PriorInvestor05Update} alt="our Prior-Investers" height="50" width="auto" />
      </div>
      <div className="img-item-list">
        <img src={matches ? Legacy : Legacy_Mobile} alt="our Prior-Investers" height="50" width="auto" />
      </div>
      <div className="img-item-list">
        <img src={PriorInvestor07Update} alt="our Prior-Investers" height="50" width="auto" />
      </div>
      <div className="img-item-list">
        <img src={PriorInvestor08Update} alt="our Prior-Investers" height="50" width="auto" />
      </div>
    </div>
  )
}

export default OurPriorInvestments

import * as actionTypes from './actionTypes'

// Define the initial state for the Redux store
const INITIAL_STATE = {
  loginFailed: false,
  signupFailed: false,
  updateUserInfoFailed: false,
  scoutDeals: [],
  scoutDealsCount: 0,
  scoutLoader: true,
  hideScoutLoadButton: false,
  scoutPageNum: 1,
  confirmedEmail: false,
  searchField: '',
  stepScout: 0,
  saveAndExitClicked: false,
  user: {
    isGoogleLogged: false,
    googleProfileObj: {},
  },
  loading: false,
  profile_data: {},
  emailNotifications: { referral: false, contract: false, promotional: false },
  startupsRefferals: { signedUp: [], diligence: [], result: [], syndicate: [] },
  investorsRefferals: { signedUp: [], diligence: [], result: [], investment: [] },
  unread_notification_count: 0,
  unreadNotifications: [],
  notifications: [],
  notificationsCount: 0,
  contracts: [],
  scoutInviteHistoryData: [],
  scoutInviteHistoryCount: 0,
  profileSettingsActiveTabKey: 'carry-recipient',
  carryEntities: {},
  contractsData: {},
}

// Define a Redux reducer function
const scoutFlowControlReducer = (state = INITIAL_STATE, action) => {
  const { type, payload, page, totalCount } = action
  switch (type) {
    case actionTypes.NEXT_STEP_SCOUT:
      return {
        ...state,
        stepScout: state.stepScout + 1,
      }
    case actionTypes.SET_SCOUT_CONFIRMED_EMAIL:
      return {
        ...state,
        confirmedEmail: true,
      }
    case actionTypes.Save_And_Exit_Clicked: {
      return {
        ...state,
        saveAndExitClicked: true,
      }
    }
    case actionTypes.Save_And_Exit_Clicked_Reset: {
      return {
        ...state,
        saveAndExitClicked: false,
      }
    }
    case actionTypes.UPDATE_SCOUT_STEP:
      return {
        ...state,
        stepScout: payload,
      }
    case actionTypes.SCOUT_SIGNUP_FAILED:
      return {
        ...state,
        signupFailed: true,
        loginFailed: false,
        updateUserInfoFailed: false,
        confirmedEmail: false,
        searchField: '',
        user: {
          isGoogleLogged: false,
          googleProfileObj: {},
        },
      }
    case actionTypes.SCOUT_LOGIN_FAILED:
      return {
        ...state,
        loginFailed: true,
      }
    case actionTypes.FETCH_SCOUT_PROFILE_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.FETCH_SCOUT_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile_data: action.payload,
      }
    case actionTypes.FETCH_SCOUT_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.EDIT_SCOUT_PROFILE_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.EDIT_SCOUT_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile_data: action.payload,
      }
    case actionTypes.EDIT_SCOUT_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.UPLOAD_SCOUT_PROFILE_PICTURE_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.UPLOAD_SCOUT_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile_data: { ...state.profile_data, profile_pic: action.payload?.url },
      }
    case actionTypes.UPLOAD_SCOUT_PROFILE_PICTURE_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.UPDATE_SCOUT_PASSWORD_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.UPDATE_SCOUT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.UPDATE_SCOUT_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_SCOUT_EMAIL_NOTIFICATIONS_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_SCOUT_EMAIL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        emailNotifications: action.payload,
      }
    case actionTypes.GET_SCOUT_EMAIL_NOTIFICATIONS_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.SET_SCOUT_EMAIL_NOTIFICATIONS_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.SET_SCOUT_EMAIL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        emailNotifications: action.payload,
      }
    case actionTypes.SET_SCOUT_EMAIL_NOTIFICATIONS_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.BULK_EMAIL_INVITE_SCOUT_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.BULK_EMAIL_INVITE_SCOUT_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.BULK_EMAIL_INVITE_SCOUT_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.EMAIL_INVITE_SCOUT_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.EMAIL_INVITE_SCOUT_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.EMAIL_INVITE_SCOUT_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_SCOUT_STARTUP_REFERRALS_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_SCOUT_STARTUP_REFERRALS_SUCCESS:
      return {
        ...state,
        loading: false,
        startupsRefferals: {
          signedUp: action?.payload?.Signed_up,
          diligence: action?.payload?.Diligence,
          result: action?.payload?.Result,
          syndicate: action?.payload?.Syndicate,
        },
      }
    case actionTypes.GET_SCOUT_STARTUP_REFERRALS_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_SCOUT_INVESTOR_REFERRALS_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.CHANGE_SETTINGS_PROFILE_ACTIVE_KEY:
      return {
        ...state,
        profileSettingsActiveTabKey: action?.payload,
      }
    case actionTypes.GET_SCOUT_INVESTOR_REFERRALS_SUCCESS:
      return {
        ...state,
        loading: false,
        investorsRefferals: {
          signedUp: action?.payload?.Signed_up,
          diligence: action?.payload?.Diligence,
          result: action?.payload?.Result,
          investment: action?.payload?.Invested,
        },
      }
    case actionTypes.GET_SCOUT_INVESTOR_REFERRALS_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.FETCH_SCOUT_UNREAD_NOTIFICATION_COUNT_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.FETCH_SCOUT_UNREAD_NOTIFICATION_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        unread_notification_count: action?.payload?.count || 0,
      }
    case actionTypes.FETCH_SCOUT_UNREAD_NOTIFICATION_COUNT_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.FETCH_SCOUT_UNREAD_NOTIFICATIONS_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.FETCH_SCOUT_UNREAD_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        unreadNotifications: action?.payload?.data,
      }
    case actionTypes.FETCH_SCOUT_UNREAD_NOTIFICATIONS_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.FETCH_SCOUT_NOTIFICATIONS_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.FETCH_SCOUT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: action?.payload?.data,
        notificationsCount: action?.payload?.total_count,
      }
    case actionTypes.FETCH_SCOUT_NOTIFICATIONS_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.CLEAR_SCOUT_NOTIFICATIONS_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.CLEAR_SCOUT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        unreadNotifications: [],
        unread_notification_count: 0,
      }
    case actionTypes.CLEAR_SCOUT_NOTIFICATIONS_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.FETCH_SCOUT_CONTRACTS_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.FETCH_SCOUT_CONTRACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        contracts: action?.payload?.data,
        contractsData: action?.payload,
      }
    case actionTypes.FETCH_SCOUT_CONTRACTS_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.COMPLETE_SCOUT_TOUR_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.COMPLETE_SCOUT_TOUR_SUCCESS:
      return {
        ...state,
        loading: false,
        profile_data: { ...state?.profile_data, tour_taken: true },
      }
    case actionTypes.COMPLETE_SCOUT_TOUR_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_INVITE_HISTORY_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_INVITE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        scoutInviteHistoryData: action?.payload?.data,
        scoutInviteHistoryCount: action?.payload?.total_count,
      }
    case actionTypes.GET_INVITE_HISTORY_FAILED:
      return {
        ...state,
        loading: false,
        scoutInviteHistoryData: [],
      }

    case 'SET_SCOUT_LOADER':
      return {
        ...state,
        scoutLoader: true,
      }
    case 'SET_HIDE_SCOUT_LOADER':
      return {
        ...state,
        scoutLoader: false,
      }
    case 'SET_HIDE_SCOUT_LOAD_BUTTON':
      return {
        ...state,
        hideScoutLoadButton: payload,
      }

    case 'GET_SCOUT_DEALS_LIST':
      return {
        ...state,
        scoutLoader: false,
        scoutDeals: payload,
        scoutPageNum: page,
        scoutDealsCount: totalCount,
      }

    case 'GET_SCOUT_DEALS':
      return {
        ...state,
        scoutLoader: false,
        scoutDeals: state.scoutDeals.concat(payload),
        scoutPageNum: page,
        scoutDealsCount: totalCount,
      }

    case 'CLEAR_SCOUT_DEALS':
      return {
        ...state,
        scoutLoader: false,
        scoutDeals: [],
        scoutPageNum: 1,
        hideScoutLoadButton: false,
      }

    case actionTypes.GET_CARRY_ENTITIES_INIT:
      return {
        ...state,
        scoutLoader: true,
        carryEntities: {},
      }

    case actionTypes.GET_CARRY_ENTITIES_SUCCESS:
      return {
        ...state,
        scoutLoader: false,
        carryEntities: {
          primary_entity: action.payload.primary_entity,
          other_carry_entities: action.payload.other_carry_entities,
        },
      }

    case actionTypes.GET_CARRY_ENTITIES_FAILED:
      return {
        ...state,
        scoutLoader: false,
        carryEntities: {},
      }
    default:
      return state
  }
}

export default scoutFlowControlReducer
